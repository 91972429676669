<template>
  <TableLayout>
    <template v-slot:panel>
      <v-container fluid class="px-5 dark_blue">
        <v-row style="gap: 5px" class="flex-column py-1">
          <v-col class="elevation-5 col-ajust">
            <ParentTab :tab="parentTabValue" @changeTab="changeParentTab" />
          </v-col>
        </v-row>
        <Panel :tab="tab" :parentTab="parentTabValue" @changeTab="changeTab" />
      </v-container>
    </template>
    <template v-slot:table>
      <v-container fluid class="pa-0 table-panel-grid overflow-y-auto">
        <Table :tab="tab" :parentTabValue="parentTabValue" />
      </v-container>
    </template>
  </TableLayout>
</template>

<script>
import TableLayout from "@/layout/TableLayout.vue";
import Table from "@/components/Reports/Table.vue";
import Panel from "@/components/Reports/Panel.vue";
import ParentTab from "@/components/Reports/ParentTab.vue";

export default {
  name: "Reports",
  components: {
    TableLayout,
    Table,
    Panel,
    ParentTab,
  },
  data() {
    return {
      tab: 0,
      parentTabValue: 0,
    };
  },

  methods: {
    changeTab(index) {
      this.tab = index;
    },
    changeParentTab(value) {
      this.parentTabValue = value;
      this.tab = 0;
    },
  },
};
</script>

<style></style>
