<template>
  <v-container
    class="fill-height ma-0 pa-0 "
    style="background-color:#272727"
    fluid
  >
    <v-flex class="d-flex justify-center align-center fill-height">
      <!-- <v-container class="pa-5 ma-5 box elevation-24" style="background-color:yellow"> -->
      <!-- <transition name="fade" appear> -->
      <form-login
        @login="submit"
        :loading="loading"
        :forgot_password="forgot_password"
      >
        <template v-slot:text-header>
          <h2 class="primary--text text--accent-3">Asset Management</h2>
          <p>Forgot Password?</p>
        </template>
      </form-login>
      <!-- </transition> -->
      <!-- </v-container> -->
    </v-flex>
  </v-container>
</template>

<script>
import FormLogin from "@/components/Login/Form.vue";
import { forgot_password } from "@/helpers/api/indexv2.js";
import { mapMutations } from "vuex";
export default {
  components: { FormLogin },
  created() {
    let { forgot_password } = this.$route.meta;
    this.forgot_password = forgot_password;
  },
  data() {
    return {
      loading: false,
      forgot_password: false,
    };
  },
  methods: {
    ...mapMutations(["setSnackbar"]),
    async submit(credentials) {
      this.loading = true;
      let res;
      try {
        res = await forgot_password(credentials);

        if (res.status === 200) {
          this.setSnackbar({
            show: "showLog",
            text: res.data.message,
            status: "success",
          });
          this.$router.push("/login");
        } else {
          console.log(res.data);
          this.setSnackbar({
            show: "showLog",
            text: res.data.message,
            status: "danger",
          });
        }
      } catch (error) {
        console.log(error);
      }

      this.loading = false;
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 1s;
  transition-property: opacity;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}

.scale-enter-active {
  animation: bounce-in 1s reverse;
}
.scale-leave-active {
  animation: bounce-in 1s;
}
</style>

<style scoped>
.boxB {
  height: 90% !important;
  border-radius: 0.5em;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 11%,
    rgba(0, 125, 255, 1) 51%,
    rgba(9, 9, 121, 1) 73%,
    rgba(3, 141, 208, 1) 99%
  );
}
</style>
