<template light>
  <v-card light class="inv-warning-top" style="width: 100vw; padding: 10px">
    <v-card-title class="card-t">
      Auction Invites
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>

      <!-- <v-spacer></v-spacer> -->
      <!-- <v-btn color="primary" @click="exportTemplate('inventoryWarning')">Export</v-btn> -->
    </v-card-title>
    <v-data-table
      dense
      light
      hide-default-footer
      class="elevation-5 rounded-0 pa-0 ma-0 overflow-y"
      fixed-header
      :headers="header"
      :items="items"
      :items-per-page="-1"
      :search="search"
      height="80vh"
    ></v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { generateHeaderData } from "@/utils/tables.js";

export default {
  async created() {
    await this.getList();

    this.items = this.list;

    this.tableDataCopy = this.items.map((x) => {
      return { ...x };
    });
  },
  computed: {
    ...mapState({
      list: (state) => state.moduleEmails.list,
    }),
    header() {
      const HEADER = [
        { text: "Auction Name", value: "Auction Name" },
        { text: "Customer Email", value: "Customer Email" },
        { text: "Accepted Invite", value: "Accepted Invite" },
        { text: "Invite Link", value: "Invite Link" },
      ];
      return generateHeaderData(HEADER);
    },
  },
  data() {
    return {
      search: "",

      items: [],
    };
  },

  methods: {
    ...mapActions({
      getList: "moduleEmails/getList",
    }),
  },
};
</script>

<style>
.inv-warning-top .card-t {
  font-size: 1.5rem !important;
}
.inv-warning-top span {
  font-size: 1.2rem !important;
}

.inv-warning-top tr td {
  font-size: 1.1rem !important;
  /* font-weight: 400; */
}
</style>
