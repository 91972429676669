<template light>
  <v-card light class="inv-warning-top" style="width: 100vw; padding: 10px">
    <div>
      <div style="padding-bottom: 1rem">
        <v-menu offset-y style="padding: 1rem">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              Select SQL Report
            </v-btn>
            <span style="padding: 1rem"> {{ reportName }}</span>
            <v-btn
              @click="exportFile()"
              outlined
              color="black"
              dark
              style="float: right"
            >
              Export
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="clickedReportListItem"
              v-for="(item, index) in reportItems"
              :key="index"
            >
              <v-list-item-title @click="clickedReportListItem">{{
                item.title
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <div style="width: 95vw; height: 89vh; z-index: 1" id="hot"></div>
    </div>
  </v-card>
</template>

<script>
import "handsontable/dist/handsontable.full.css";
import Handsontable from "handsontable";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      listSQLReports: (state) => state.moduleSQLReports.listSQLReports,
      listSQLReportsNames: (state) =>
        state.moduleSQLReports.listSQLReportsNames,
    }),
  },
  async created() {
    await this.getSQLReport({ name: "test" });
    await this.getSQLReport({ getList: "true" });
    this.reportItems = this.listSQLReportsNames.data.map((x) => ({
      title: x.name,
    }));
    console.log("reportItems", JSON.stringify(this.reportItems));

    const elm = await this.waitForElm("#hot");

    this.initTable();
    this.buildColumns();
  },

  data: function () {
    return {
      data: [],
      cols: [],
      reportItems: [],
      location: "end",
      handsontableInstance: null,
      reportName: "",
    };
  },
  components: {},

  methods: {
    async clickedReportListItem(e) {
      console.log("Clicked report item");
      this.reportName = e.target.innerText;
      await this.getSQLReport({ name: e.target.innerText });

      await this.getData();
      await this.buildColumns();

      this.initTable();
    },
    ...mapActions({
      getSQLReport: "moduleSQLReports/getSQLReport",
    }),
    getData() {
      this.data = this.listSQLReports.data;
    },

    buildColumns() {
      let cols = Object.keys(this.data[0]);
      let newObjCols = [];
      for (let col of cols) {
        newObjCols.push({
          title: col,
          type: "text",
          data: col,
        });
      }
      this.cols = newObjCols;
    },

    async initTable() {
      try {
        this.handsontableInstance.destroy();
      } catch (error) {}

      await this.getData();
      await this.buildColumns();

      let container = window.window.document.getElementById("hot");

      const handsontableInstance = new Handsontable(container, {
        data: [...this.data],
        columns: [...this.cols],
        filters: true,
        dropdownMenu: true,
        height: "auto",
        autoWrapRow: true,
        autoWrapCol: true,
        licenseKey: "non-commercial-and-evaluation",
        rowHeaders: true,
        colHeaders: true,
        multiColumnSorting: true,
      });
      this.handsontableInstance = handsontableInstance;
    },

    waitForElm(selector) {
      return new Promise((resolve) => {
        if (window.document.querySelector(selector)) {
          return resolve(window.document.querySelector(selector));
        }

        const observer = new MutationObserver((mutations) => {
          if (window.document.querySelector(selector)) {
            observer.disconnect();
            resolve(window.document.querySelector(selector));
          }
        });

        observer.observe(window.document.body, {
          childList: true,
          subtree: true,
        });
      });
    },

    // toDay() {
    //   var today = new Date();
    //   var dd = today.getDate();
    //   var mm = today.getMonth() + 1; //January is 0!

    //   var yyyy = today.getFullYear();
    //   if (dd < 10) {
    //     dd = "0" + dd;
    //   }
    //   if (mm < 10) {
    //     mm = "0" + mm;
    //   }
    //   return dd + "." + mm + "." + yyyy;
    // },

    exportFile() {
      // this.exportTableToExcel("htCore");

      let exportPlugin1 = this.handsontableInstance.getPlugin("exportFile");

      exportPlugin1.downloadFile("csv", {
        bom: false,
        columnDelimiter: ",",
        columnHeaders: true,
        exportHiddenColumns: true,
        exportHiddenRows: true,
        fileExtension: "csv",
        filename: `SQlReport-${this.reportName}_[YYYY]-[MM]-[DD]`,
        mimeType: "text/csv",
        rowDelimiter: "\r\n",
        rowHeaders: true,
      });
    },

    //   exportTableToExcel(tableClass) {
    //     var downloadLink;
    //     var dataType = "application/vnd.ms-excel";
    //     //var tableSelect = window.document.getElementById(tableID);
    //     var tableSelect = window.document.getElementsByClassName("htCore")[0];
    //     console.log("tableSelect", tableSelect);
    //     var tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

    //     // Specify file name
    //     var filename = "Export_" + this.toDay() + ".xls";

    //     // Create download link element
    //     downloadLink = window.document.createElement("a");

    //     window.document.body.appendChild(downloadLink);

    //     if (navigator.msSaveOrOpenBlob) {
    //       var blob = new Blob(["\ufeff", tableHTML], {
    //         type: dataType,
    //       });
    //       navigator.msSaveOrOpenBlob(blob, filename);
    //     } else {
    //       // Create a link to the file
    //       downloadLink.href = "data:" + dataType + ", " + tableHTML;
    //       // Setting the file name
    //       downloadLink.download = filename;
    //       //triggering the function
    //       downloadLink.click();
    //     }
    //   },
  },
};
</script>
