<template>
  <v-container class="upload-top ma-auto text-end dark-blue pa-3">
    <v-btn
      type="button"
      class="btn btn-primary mb-1"
      color="primary"
      small
      href="https://storage.googleapis.com/nsc-static-assets/vendor/vendor-inventory-template-1.xlsx"
      >Download template</v-btn
    >
    <v-stepper
      v-model="stepValue"
      alt-labels
      dense
      class="fill-height pa-5 d-flex justify-space-around flex-column"
    >
      <v-stepper-header class="pa-0 ma-0">
        <v-stepper-step
          @click="resetUpload"
          step="1"
          class="ma-0"
          :complete="stepValue > 1"
        >
          Upload File
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="stepValue > 2"
          step="2"
          class="ma-0"
          :rules="[
            () => errTrig == 0 || errTrig == 1 || errTrig == 99 || errTrig == 2,
          ]"
        >
          Upload Validation
        </v-stepper-step>

        <v-divider></v-divider>
        <v-stepper-step :complete="stepValue > 2" step="3" class="ma-0"
          >Import Successful</v-stepper-step
        >
      </v-stepper-header>

      <v-stepper-items>
        <template-step :step="1">
          <template v-slot:left>
            <div v-if="!file">
              <v-icon size="10vw">mdi-cloud-upload</v-icon>
              <input
                name="flat"
                type="file"
                @change="inputFile"
                id="file"
                ref="file"
              />
            </div>
            <div v-else>
              <v-icon size="10vw">mdi-file-cloud</v-icon>
              <v-btn
                type="button"
                class="btn btn-primary removeFile"
                color="error"
                @click="removeFile"
                small
                >Remove File</v-btn
              >
            </div>
          </template>
          <template v-slot:right>
            <step-1
              :file="file"
              :userName="user.name"
              v-on:c2pMessageTypeId="onChangeMessageTypeChild"
              v-on:c2pFileUploadMapName="onC2pFileUploadMapName"
              v-on:c2pBuyerPartyId="onChangeBuyerPartyIdChild"
              v-on:c2pKeepExistingLastMod="onChangeKeepExistingLastMod"
            ></step-1>
          </template>

          <template v-slot:down>
            <v-col>
              <v-btn
                block
                elevation="2"
                class="ma-0 dark-blue"
                @click="handleValidateUpload"
                >Upload</v-btn
              >
            </v-col>
            <v-col>
              <v-btn
                @click="resetUpload"
                block
                elevation="2"
                class="ma-0 dark-blue"
                >Reset</v-btn
              >
            </v-col>
          </template>
        </template-step>
        <template-step :step="2">
          <template v-slot:left>
            <v-icon size="10vw" color="success" v-if="errTrig == 0"
              >mdi-cloud-check</v-icon
            >
            <v-icon size="10vw" color="error" v-else>mdi-cloud-alert</v-icon>
          </template>
          <template v-slot:right>
            <step-2 @goToStep="onClickChildStep2" :info="validate_errors" />
          </template>
        </template-step>

        <v-card
          v-if="this.stepValue == 2 && this.errTrig > 0"
          class="mx-auto"
          max-width="100%"
          style="text-align: center"
        >
          <v-card-text>
            <p class="display-1 text--primary">
              There were validation errors with your file.
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="teal accent-4"
              @click="goToUpload"
              style="text-align: center; margin: auto"
            >
              Go back to upload
            </v-btn>
          </v-card-actions>
        </v-card>
        <template-step :step="3">
          <template v-slot:left>
            <v-icon size="10vw" color="success" v-if="file && errTrig == 0"
              >mdi-cloud-check</v-icon
            >

            <v-card class="mx-auto" max-width="100%">
              <v-card-text>
                <p class="display-1 text--primary">
                  Inventory Imported Successfully!
                </p>

                <div v-if="skipped.length > 0" style="margin-bottom: 16px">
                  <p class="subtitle-1">Some items were skipped:</p>

                  <v-expansion-panels multiple>
                    <v-divider></v-divider>
                    <v-spacer></v-spacer>
                    <v-expansion-panel
                      v-if="skipped !== []"
                      v-for="(skipp, i) in skipped"
                      :key="i"
                    >
                      <v-expansion-panel-header>
                        {{ `${Object.keys(skipp)} - ${Object.values(skipp)}` }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols="6">
                            <v-text-field
                              label="Equipment #"
                              :value="Object.keys(skipp)"
                              type="text"
                              outlined
                              disabled
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              label="Reason"
                              :value="Object.values(skipp)"
                              type="text"
                              outlined
                              dense
                              disabled
                              hide-details
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
                <!-- <div class="text--primary">
                  Click below to manage your new inventory
                </div> -->
              </v-card-text>
              <v-card-actions>
                <v-btn
                  text
                  color="teal accent-4"
                  @click="goToInventory"
                  style="text-align: center; margin: auto"
                >
                  Manage Inventory
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
          <template v-slot:right> </template>
        </template-step>
      </v-stepper-items>
    </v-stepper>

    <div
      v-if="step1_error_trig"
      style="text-align: left; padding: 1rem; padding-bottom: 0"
    >
      <v-alert
        type="error"
        v-model="step1_error_trig"
        dismissible
        color="red"
        border="left"
        elevation="2"
        colored-border
        icon="mdi-alert-octagon-outline"
        style="text-align: left"
      >
        {{ this.step1_error_msg }}
      </v-alert>
    </div>
  </v-container>
</template>

<style scoped>
@media only screen and (min-width: 1264px) {
  .upload-top {
    max-width: 80%;
  }
}

@media only screen and (min-width: 1500px) {
  .upload-top {
    max-width: 60%;
  }
}
</style>

<script>
import Step1 from "@/components/Upload/Step1.vue";
import Step2 from "@/components/Upload/Step2.vue";
import TemplateStep from "@/components/Upload/TemplateStep.vue";
// import Spinner from "../../components/General/Spinner.vue";

import XLSX from "xlsx";

// import {
//   validate_errors_400,
//   validate_errors_php_string,
// } from "../../helpers/mocks/validation.js";

import {
  upload_inventory_file_new_system,
  upload_inventory_file,
  upload_inventory_file_step2,
  validate_inventory_file,
  convert_inventory_file,
  // get_visible_entities,
  // test_5min_timeeout,
} from "@/helpers/api/indexv2.js";

import { mapState } from "vuex";
export default {
  components: {
    TemplateStep,
    Step1,
    Step2,
  },
  data() {
    return {
      file: "",
      stepValue: 1,
      messageTypeIdChild: 3,
      convertBuyerPartyId: 1,
      errTrig: 0,
      extraInfo:
        "Your inventory imported successfully. You can now manage that inventory.",
      validate_errors: {},
      step1_error_msg: "",
      step1_error_trig: false,
      skipped: [],
      keepExistingLastModChild: false,
    };
  },

  // async mounted() {
  //   const testc = await test_5min_timeeout();
  //   console.log("testc", testc);

  //   if (testc.status == 200) {
  //     console.log("OK");
  //   }

  //   if (testc.status == 401) {
  //     console.log("GOT YA - 401");
  //   }

  //   if (testc.status == 400) {
  //     console.log("GOT YA - 400");
  //   }

  //   if (testc.status == 500) {
  //     console.log("GOT YA - 500");
  //   }

  //   if (testc.status == 600) {
  //     console.log("GOT YA - TIMEOUT");
  //   }
  // },

  methods: {
    resetUpload() {
      console.log("Reset");

      this.file = "";
      this.errTrig = 0;
      this.validate_errors = {};
      this.messageTypeIdChild = 3;
      this.convertBuyerPartyId = 1;
      this.stepValue = 1;
      this.step1_error_msg = "";
      this.step1_error_trig = false;
      this.skipped = [];
      this.keepExistingLastModChild = false;
    },

    onClickChildStep2(value) {
      this.stepValue = value;
    },

    goToUpload() {
      this.resetUpload();
      this.stepValue = 1;
    },

    goToInventory() {
      this.$router.push("/inventory");
    },

    onChangeMessageTypeChild(value) {
      this.messageTypeIdChild = value;

      console.log(value);
    },

    onChangeKeepExistingLastMod(value) {
      this.keepExistingLastModChild = value;
    },

    onC2pFileUploadMapName(value) {
      //
      console.log("onC2pFileUploadMapName value", value);
      this.mapName = value;
    },

    onChangeBuyerPartyIdChild(value) {
      this.convertBuyerPartyId = value;
    },

    async handleValidateUpload(e) {
      const self = this;

      console.log(this.messageTypeIdChild);

      // IF SALES PROGRAM REPORT
      if (this.messageTypeIdChild !== 3) {
        self.$store.commit("setSpinnerShow", true);

        let formData = new FormData();
        formData.append("flat", this.file);
        formData.append("messageTypeId", this.messageTypeIdChild);
        formData.append("keepExistingLastMod", this.keepExistingLastModChild);

        try {
          this.stepValue = 1;
          await upload_inventory_file_new_system(
            formData,
            this.mapName,
            this.keepExistingLastModChild,
          );
          this.stepValue = 3;
        } catch (error) {
          this.stepValue = 1;

          return console.log(
            `Error ON UPLOAD: ${this.errTrig} - ${JSON.stringify(
              errorTrigMessage,
            )}`,
          );
        }

        self.$store.commit("setSpinnerShow", false);

        return;
      }

      // test start
      var f = this.file;
      var reader = new FileReader();

      const readerProm = new Promise((resolve, reject) => {
        reader.onload = function (ee) {
          try {
            var data = ee.target.result;
            data = new Uint8Array(data);
            var workbook = XLSX.read(data, { type: "array" }); // parse the file
            var sheet = workbook.Sheets[workbook.SheetNames[0]]; // get the first worksheet
            var range = XLSX.utils.decode_range(sheet["!ref"]); // get the range
            console.log("range.e.r", range.e.r);

            if (range) {
              if (range.e) {
                if (range.e.r) {
                  if (range.e.r > 19999) {
                    self.errTrig = 99;
                    setTimeout(function () {
                      self.step1_error_trig = true;
                      self.step1_error_msg =
                        "File size too large. Exceeds 20,000 row limit.";

                      resolve(true);
                    }, 2000);
                  } else {
                    resolve(false);
                  }
                }
              }
            }
          } catch (error) {
            console.log("in readerProm catch", error);
            self.step1_error_trig = true;
            self.step1_error_msg = "Error: Unable to parse file";

            self.errTrig = 99;
            resolve(true);
          }
        };

        reader.readAsArrayBuffer(f);
      });

      self.$store.commit("setSpinnerShow", true);
      const isRowsCountLimit = await readerProm.then((x) => x == true);
      // self.errTrig = isRowsCountLimit ? 99 : 0;
      self.$store.commit("setSpinnerShow", false);

      // return;
      // test end

      // stepValue = 2
      let formData = new FormData();
      formData.append("uploadedFile", this.file);
      formData.append("messageTypeId", this.messageTypeIdChild);

      let errorTrigMessage = "";
      let upload_res;
      let upload_2_res;
      let validate_res;
      let conv_res;

      console.log("this.errTrig", this.errTrig);

      if (self.errTrig == 99) {
        return console.log(
          `Error ON UPLOAD: ${this.errTrig} - ${JSON.stringify(
            errorTrigMessage,
          )}`,
        );
      }

      // --------------------------
      this.$store.commit("setSpinnerShow", true);
      try {
        upload_res = await upload_inventory_file(formData);

        this.$store.commit("setSpinnerShow", false);
        try {
          if (upload_res.indexOf("error") !== -1) {
            this.errTrig = this.errTrig + 1;
            errorTrigMessage = upload_res;
          } else {
            if (upload_2_res["status"] == 500) {
              this.errTrig = this.errTrig + 1;
              errorTrigMessage =
                "Error: Unable to parse file. Corrupt or incompatible.";
            }

            if (upload_res["status"] == 600) {
              this.errTrig = this.errTrig + 1;
              errorTrigMessage = "Request cancelled. Server timed out";
            }

            if (upload_res["status"] == 400) {
              this.errTrig = this.errTrig + 1;
              errorTrigMessage = upload_res["message"];
            }
          }
        } catch (e) {}
      } catch (error) {
        this.errTrig = this.errTrig + 1;
        errorTrigMessage = error;
      }

      console.log("this.errTrig", this.errTrig);

      if (this.errTrig > 0) {
        this.$store.commit("setSpinnerShow", false);

        self.step1_error_trig = true;
        self.step1_error_msg = errorTrigMessage;

        return console.log(
          `Error ON UPLOAD: ${this.errTrig} - ${JSON.stringify(
            errorTrigMessage,
          )}`,
        );
      }

      // ---------------------------
      this.$store.commit("setSpinnerShow", true);
      try {
        upload_2_res = await upload_inventory_file_step2({
          file_id: parseInt(upload_res.file_id),
          file_location: upload_res.file_location,
          // file_location: "FAIL",
        });

        this.$store.commit("setSpinnerShow", false);

        try {
          if (upload_2_res.indexOf("error") !== -1) {
            this.errTrig = this.errTrig + 1;
            errorTrigMessage =
              "Error: Unable to parse file. Corrupt or incompatible.";

            console.log('upload_2_res["status"] 1', upload_2_res);

            if (!upload_2_res) {
              console.log("upload_2_res UNDEFINED");
              this.errTrig = this.errTrig + 1;
              errorTrigMessage =
                "Error: Unable to parse file. Corrupt or incompatible.";
            } else {
              if (!upload_2_res["status"]) {
                console.log("upload_2_res['status'] UNDEFINED");
                this.errTrig = this.errTrig + 1;
                errorTrigMessage =
                  "Error: Unable to parse file. Corrupt or incompatible.";
              }
            }
          } else {
            console.log('upload_2_res["status"] 2', upload_2_res["status"]);
            if (!upload_2_res["status"]) {
              console.log("ubdefubed 2");
              this.errTrig = this.errTrig + 1;
              errorTrigMessage =
                "Error: Unable to parse file. Corrupt or incompatible.";
            }

            if (upload_2_res["status"] == 500) {
              this.errTrig = this.errTrig + 1;
              errorTrigMessage =
                "Error: Unable to parse file. Corrupt or incompatible.";
            }

            if (upload_2_res["status"] == 600) {
              this.errTrig = this.errTrig + 1;
              errorTrigMessage = "Request cancelled. Server timed out";
            }

            if (upload_2_res["status"] == 400) {
              this.errTrig = this.errTrig + 1;
              errorTrigMessage = upload_2_res["message"];
            }
          }
        } catch (e) {}
      } catch (error) {
        this.errTrig = this.errTrig + 1;
        errorTrigMessage = error;
      }

      if (this.errTrig > 0) {
        this.$store.commit("setSpinnerShow", false);

        this.stepValue = 1;
        self.step1_error_trig = true;
        self.step1_error_msg = errorTrigMessage;

        return console.log(
          `Error: ${this.errTrig} - ${JSON.stringify(errorTrigMessage)}`,
        );
      }

      // --------------------------
      this.$store.commit("setSpinnerShow", true);
      try {
        // validate_res = validate_errors_400();
        // validate_res = validate_errors_php_string();

        validate_res = await validate_inventory_file({
          file_id: parseInt(upload_res.file_id),
        });

        this.$store.commit("setSpinnerShow", false);

        try {
          if (validate_res.indexOf("error") !== -1) {
            this.errTrig = this.errTrig + 1;
            errorTrigMessage = "There was an unexpected error.";
          }
        } catch (e) {}
        if ("status" in validate_res) {
          if (validate_res["status"] == 400) {
            if (validate_res["data"]["has_errors"]) {
              const newar = validate_res["data"]["errors"].map((x) => {
                return {
                  colName: x.errorDetails.colName,
                  rowNum: x.errorDetails.rowNum,
                  errDesc: x.errorDetails.errDesc,
                  errResp: x.errorDetails.errResp,
                  valueWithError: x.valueOut,
                };
              });

              this.errTrig = this.errTrig + 1;
              errorTrigMessage = newar;
            }
          } else {
            if (validate_res["status"] == 600) {
              this.errTrig = this.errTrig + 1;
              errorTrigMessage = "Request cancelled. Server timed out";
            }

            if (validate_res["status"] == 400) {
              this.errTrig = this.errTrig + 1;
              errorTrigMessage = validate_res["message"];
            }
          }
        }
      } catch (error) {
        this.errTrig = this.errTrig + 1;
        errorTrigMessage = error;
      }

      if (this.errTrig > 0) {
        this.$store.commit("setSpinnerShow", false);

        this.validate_errors = errorTrigMessage;
        this.stepValue = 2;

        self.step1_error_trig = false;
        self.step1_error_msg = errorTrigMessage;

        return console.log(
          `Error ON VALIDATION: ${this.errTrig} - ${JSON.stringify(
            errorTrigMessage,
          )}`,
        );
      }

      // --------------------------
      this.$store.commit("setSpinnerShow", true);
      try {
        conv_res = await convert_inventory_file({
          file_id: parseInt(upload_res.file_id),
          visibleEntityId: this.convertBuyerPartyId,
        });

        this.$store.commit("setSpinnerShow", false);

        try {
          if (conv_res.indexOf("error") !== -1) {
            this.errTrig = this.errTrig + 1;
            errorTrigMessage = conv_res;
          } else {
            if (conv_res["status"] == 600) {
              this.errTrig = this.errTrig + 1;
              errorTrigMessage = "Request cancelled. Server timed out";
            }

            if (conv_res["status"] == 400) {
              this.errTrig = this.errTrig + 1;
              errorTrigMessage = conv_res["message"];
            }
          }
        } catch (e) {}
      } catch (error) {
        this.errTrig = this.errTrig + 1;
        errorTrigMessage = error;
      }

      if (this.errTrig > 0) {
        this.$store.commit("setSpinnerShow", false);

        this.stepValue = 1;

        self.step1_error_trig = true;
        self.step1_error_msg =
          "Error: Unable to convert inventory. Please check validation errors.";

        return console.log(
          `Error ON CONVERSION: ${this.errTrig} - ${JSON.stringify(
            errorTrigMessage,
          )}`,
        );
      } else {
        this.skipped = conv_res["skipped"];
      }

      this.stepValue = 3;
    },
    inputFile(e) {
      var files = e.target.files || e.dataTransfer.files;

      let file = e.target.files[0];

      if (!files.length) {
        return;
      }

      this.file = file;
      if (
        this.extension === "php" ||
        this.extension === "exe" ||
        this.extension === "bat" ||
        this.extension === "jpg" ||
        this.extension === "jpeg"
      ) {
        alert("invalid format");
      }

      // this.file = this.$refs.file.files[0];
    },

    removeFile() {
      this.file = "";
    },
    onComplete: function () {
      alert("Yay. Done!");
    },
  },
  computed: {
    ...mapState(["user"]),
    extension() {
      return this.file ? this.file.name.split(".").pop() : "";
    },
  },
};
</script>

<style scoped>
.uploadFile {
  height: 30%;
  position: relative;
}

.uploadFile:hover {
  border: 1px solid #2e94c4;
}

.uploadFile input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.v-stepper__step {
  padding-left: 0;
  padding-right: 0;
}
</style>
