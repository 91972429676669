<template>
  <v-container class="ma-0 pa-3 fill-height test-a" fluid>
    <v-row justify="center" class="dark-blue ma-0 pa-0" style="height: 25%">
      <v-col cols="2" class="ma-0 pa-0 fill-height">
        <Header :user="user" />
      </v-col>
    </v-row>
    <v-row justify="center" class="dark-blue ma-0 pa-0" style="height: 75%">
      <v-col class="ma-0 pa-0 fill-height">
        <Menu />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserCard from "@/components/Settings/UserCard.vue";
import PersonalDetails from "@/components/Settings/PersonalDetails.vue";
import Notifications from "@/components/Settings/Notifications.vue";
import Security from "@/components/Settings/Security.vue";
import Tabs from "@/components/Settings/Tabs.vue";
import Header from "@/components/Settings/Header.vue";
import Menu from "@/components/Settings/Menu.vue";
import Releases from "@/components/Settings/Releases.vue";
import { mapState } from "vuex";
export default {
  components: {
    UserCard,
    PersonalDetails,
    Notifications,
    Security,
    Tabs,
    Header,
    Menu,
    Releases,
  },
  computed: {
    ...mapState(["user"]),
  },
};
</script>

<style scoped lang="scss">
.test-a {
  /* background-color: $v-base-primary; */
  background-color: var(--v-primary-base);
}
.min-w {
  min-width: 30vw;
}

@media only screen and (min-width: 1600px) {
  .row-h {
    height: 70%;
  }
}

@media only screen and (min-width: 1900px) {
  .row-h {
    height: 50%;
  }
}
</style>
